<template>
  <div class="banner">
    <div class="banner-width">
      <Banner/>
      <div class="skill-form">
        <div class="skill-form-center">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">

            <!-- 标题 -->
            <el-form-item label="给您提供的服务起一个标题" prop="subject">
              <el-input v-model="ruleForm.subject" placeholder="要求简介清晰"></el-input>
            </el-form-item>
            <!-- 详细描述 -->
            <el-form-item label="请详细描述一下您的服务" prop="content">
              <el-input
                  type="textarea"
                  :rows="3"
                  class="skill-description"
                  v-model="ruleForm.content"
                  maxlength="300"
                  placeholder="介绍你服务包含哪些内容;最多可输入300个字符"
                  show-word-limit
              ></el-input>
            </el-form-item>

            <div class="nextDiv" v-for="(item, index) in nextShowList" :key="index">
              <!-- 技能 -->
              <el-form-item v-loading="loading" v-if="item.key==1&&item.is_show" label="完成该工作需要什么技能" prop="skill_ids"
                            class="task-work">
                <Skills :title="'系统猜测您的任务属于'" :styleAttrs="{width:'718px'}" @skillCallBack="skillCallBack"/>
              </el-form-item>

              <!-- 收费方式 -->
              <el-form-item label="收费方式" prop="price" v-show="item.key==2&&item.is_show">
                <div class="skill-money">
                  <div class="skill-money-left">
                    <el-button size="mini" :style="hourBtnActive? activePayWay.style : uactivePayWay.style"
                               @click="changeBtn( 'hour' )">
                      <Isvg :icon-class="hourBtnActive ? activePayWay.hourIcon : uactivePayWay.hourIcon" alt=""/>
                      按小时收费
                    </el-button>

                    <el-button size="mini" :style="fixedBtnActive? activePayWay.style : uactivePayWay.style"
                               @click="changeBtn( 'fixed' )">
                      <Isvg :icon-class="fixedBtnActive ? activePayWay.fixedIcon : uactivePayWay.fixedIcon" alt=""/>
                      固定价格
                    </el-button>

                  </div>
                  <div class="skill-money-right">
                    <div class="skill-money-right1" v-show="hourBtnActive">
                      <div class="skill-money-right-center">
                        <div class="skill-money-right-center-top">
                          <p>根据小时费率雇用并按计费小时数付费，最适合正在进行的工作。</p>
                        </div>
                        <div class="skill-money-right-center-bottom">
                          <div class="min-price">
                            <el-form-item label="时薪" label-width="50px">
                              <el-input type="number" v-model="ruleForm.price" id="hourly" size="mini">
                                <b slot="prefix">￥</b>
                                <b slot="suffix">元</b>
                              </el-input>
                            </el-form-item>
                          </div>
                          <div class="max-price">
                            <el-form-item label="交付周期" label-width="90px">
                              <el-input type="number" v-model="ruleForm.price" id="day" size="mini">
                                <b slot="suffix">天</b>
                              </el-input>
                            </el-form-item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="skill-money-right2" v-show="fixedBtnActive">
                      <div class="skill-money-right-center">
                        <div class="skill-money-right-center-top">
                          <p>根据小时费率雇用并按计费小时数付费，最适合正在进行的工作。</p>
                        </div>
                        <div class="skill-money-right-center-bottom">
                          <div class="min-price">
                            <el-form-item label="一口价" label-width="70px">
                              <el-input type="number" v-model="ruleForm.price" id="hourly" size="mini">
                                <b slot="prefix">￥</b>
                                <b slot="suffix">元</b>
                              </el-input>
                            </el-form-item>
                          </div>
                          <div class="max-price">
                            <el-form-item label="交付周期" label-width="90px">
                              <el-input type="number" v-model="ruleForm.price" id="day" size="mini">
                                <b slot="suffix">天</b>
                              </el-input>
                            </el-form-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-form-item>

              <!-- 上传服务展示 -->

              <div v-show="item.key==2&&item.is_show">

                <el-card style="width: 160px;height:90px;padding:unset" v-show="ruleForm.thumb != ''">
                  <el-image style="width:160px; height:90px; background:#f4f4f4" :src="ruleForm.thumb"></el-image>
                </el-card>
                <el-form-item prop="thumb">
                  <el-upload
                      :action="postUrl"
                      :headers="headers"
                      :multiple="true"
                      :file-list="ruleForm.more_thumbs"
                      :show-file-list="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      list-type="picture-card"
                      :limit=10>

                    <div style="width:136px;height:77px;background:#f4f4f4">
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="tip" style="font-size:12px;color:#b5b5b5">点击上传图片</div>
                    </div>

                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" style="width:136px;height:77px;" :src="file.url"
                           alt="">
                      <span class="el-upload-list__item-actions">
                          <span v-if="true" class="el-upload-list__item-delete" @click="handleRemovePic(file)">
                              <i class="el-icon-delete"></i>
                          </span>
                          <span v-if="true" class="el-upload-list__item-preview" @click="handleMainPreview(file)">
                            <el-tooltip class="item" effect="dark" content="设为主图" placement="bottom-end">
                              <i class="el-icon-place"></i>
                            </el-tooltip>
                          </span>
                        </span>
                    </div>
                  </el-upload>
                </el-form-item>

                <p class="skill-hint">
                  <span>提示</span>
                <ul>
                  <li>1、第一张默认为封面，封面是成单的核心；</li>
                  <li>2、建议比例16:9；</li>
                  <li>3、支持JPG、GIF格式，小于5M；</li>
                  <li>4、最多可上传10张。</li>
                </ul>
                </p>

                <div class="skill-button">
                  <el-button type="button" class="skill-button-preview" @click="toPreview()">预览</el-button>
                  <el-button type="button" class="skill-button-issue" @click="toPublic">立即发布</el-button>
                </div>
              </div>
            </div>

            <el-button type="primary" v-show="nextStepBtn" size="small" @click="nextStep()"> 下一步</el-button>
          </el-form>


          <el-dialog
              :title="previewData.subject"
              :visible.sync="dialogVisible"
              :before-close="handleClose"
              width="800px"
              center
              class="dia-font">
            <p>
            <ul>
              <li>在此时间之前，自由职业者可向您发出求职申请。时间最大不能超2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申</li>
              <li>请。时间最大不能超过2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申请。时间最大不能超过2022年2与3日申请。</li>
              <li>在此时间之前，自由职业者可向您发出求职申请。时间最大不能超过2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申</li>
              <li>请。时间最大不能超过2022年2与3日申请。</li>
            </ul>
            </p>
            <div class="check-img-font">
              <div class="check-img-border">
                <img :src="ruleForm.thumb" alt="">
              </div>
              <div class="check-font-border">
                <ul>
                  <li>
                    <strong>分类</strong>
                    <span> {{ previewData.cat_name }} </span>
                  </li>
                  <li>
                    <strong>技能</strong>
                    <span style="margin-right:10px;" v-for="(skill, key ) in previewData.skills" :key="key"> {{
                        skill
                      }} </span>
                  </li>
                  <li>
                    <strong>时薪</strong>
                    <span>￥ {{ previewData.price }} 元</span>
                  </li>
                  <li>
                    <strong>交付工期</strong>
                    <span> {{ previewData.cycle }} 天</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="check-img-box">
              <div class="check-img-boxs">

                <div class="check-img-boxs-border" v-for="(img, k) in ruleForm.more_thumbs" :key="k">
                  <img :src="img.url" alt="" />
                </div>

              </div>
            </div>
            <span slot="footer" class="dialog-footer">
             <el-button @click="dialogVisible = false" class="back-button">返回修改</el-button>
             <el-button type="primary" @click="toPublic" class="go-button">立即发布</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {createService} from '@/api/service'
import Banner from "@/components/Banner"
import Skills from "@/components/Skills";

export default {
  components: {
    Banner,
    Skills
  },
  data() {
    return {
      /** lipeng */
      nextStepBtn: true,
      nextShowList: [
        {key: 1, is_show: false},
        {key: 2, is_show: false},
      ],
      // 推荐技能
      categorys: [],
      skills: [],
      recommand_skills: [],
      // 切换收费模式
      hourBtnActive: true,
      fixedBtnActive: false,
      activePayWay: {
        style: {
          'background': '#00a2e6',
          'color': '#fff',
        },
        hourIcon: 'task-hourly-select-white',
        fixedIcon: 'task-fixed-select-white',
      },
      uactivePayWay: {
        style: {},
        hourIcon: 'task-hourly-select-no',
        fixedIcon: 'task-fixed-select-no',
      },
      // 图片上传部分
      postUrl: '/api/upload',
      headers: {Authorization: `Bearer ${this.$root.current_token}`},
      // 表单验证部分
      ruleForm: {
        subject: "",
        content: '',
        pay_way: 2,
        price: '',
        skills: [],
        thumb: '',
        more_thumbs: []
      },
      rules: {
        subject: [
          {required: true, message: "请输入服务标题", trigger: "blur"},
          {min: 3, max: 15, message: "长度在 3 到 15 个字符", trigger: "blur"},
        ],
        content: [
          {
            required: true, message: "请描述您的服务", trigger: "blur"
          }
        ],
        skills: [
          {
            required: true, message: "请选择您的技能", trigger: "blur"
          }
        ],
        pay_way: [
          {
            required: true, message: "请选择收费方式", trigger: "blur"
          }
        ],
        price: [
          {
            required: true, message: "请输入您的时薪/价格", trigger: "blur"
          }
        ],
        thumb: [
          {
            required: true, message: "请上传商品图片", trigger: "change"
          }
        ],
      },
      // 预览的dialog
      dialogVisible: false,
      previewData: {
        subject: '',
        cat_name: '',
        skills: [],
        thumb: '',
        more_thumbs: [],
        price: 0,
        cycle: 0,
      },
      /** lipeng end */

      options: [],
      list: [],
      loading: false,
    };
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPGOrGIF = ["image/jpeg", "image/gif"].indexOf(file.type) >= 0;
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPGOrGIF) {
        this.$notify({
          position: 'top-left',
          type: 'error',
          title: '通知',
          message: "上传头像图片只能是 JPG 格式 GIF格式!"
        })
      }
      if (!isLt5M) {
        this.$notify({
          position: 'top-left',
          type: 'error',
          title: '通知',
          message: "上传头像图片大小不能超过 5MB!"
        })
      }
      return isJPGOrGIF && isLt5M;
    },

    toPublic() {
      this.dialogVisible = false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let postData = {
            subject: this.ruleForm.subject,
            content: this.ruleForm.content,
            pay_way: this.ruleForm.pay_way,
            price: this.ruleForm.price,
            skills: this.ruleForm.skills,
            thumb: this.ruleForm.thumb,
            more_thumbs: []
          }
          this.ruleForm.more_thumbs.forEach(item => {
            postData.more_thumbs.push(item.url)
          })

          this.create(postData)
        } else {
          return false;
        }
      });
    },
    /** lipeng */
    // 下一步
    nextStep() {
      try {
        this.nextShowList.forEach(item => {
          if (item.is_show === false) {

            switch (item.key) {
              case 1:
                let data = {
                  subject: this.ruleForm.subject,
                  content: this.ruleForm.content,
                }
                break;
              case this.nextShowList.length:
                this.nextStepBtn = false // 最后一步删除 按钮元素
                break;
            }

            item.is_show = true
            throw new Error("ending");//跳出循环
          }
        })
      } catch (error) {
        if (error.message == "ending") {
        }
      }
    },
    // 切换收费方式
    changeBtn(type) {
      switch (type) {
        case 'hour':
          this.hourBtnActive = true
          this.fixedBtnActive = false
          this.ruleForm.pay_way = 2
          break;
        case 'fixed':
          this.hourBtnActive = false
          this.fixedBtnActive = true
          this.ruleForm.pay_way = 1
          break;
      }
    },
    // 删除图片
    handleRemovePic(file) {
      this.ruleForm.more_thumbs.splice(file, 1)
    },
    handleMainPreview(file) {
      console.log(file)
      this.ruleForm.thumb = file.url
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.more_thumbs.push(res.data[0])
    },
    // 预览
    toPreview() {
      console.log(this.ruleForm)
      this.previewData.subject = this.ruleForm.subject
      this.previewData.price = this.ruleForm.price
      this.previewData.more_thumbs = this.ruleForm.more_thumbs

      this.dialogVisible = true
    },
    skillCallBack(data) {
      this.skills = data.skills
      this.ruleForm.cat_id = data.skill_cat_id
      this.ruleForm.skill_cat_id = data.skill_cat_id
      this.ruleForm.skills = data.skill_ids
    },
    async create(data) {
      const res = await createService(data)
      if (res.statusCode == 200) {
        this.$message.success('添加成功')
        this.$router.push('/service/result/' + res.data.id)
      } else {
        this.$message.error(res.message)
      }
    }
  },
};
</script>

<style lang="less" scoped>
/** lipeng */
/deep/ .nextDiv {
  .el-form-item__content {
    margin-bottom: 1rem;
  }
}

.el-button:hover {
  color: #575757;
}

.nextStep {
  background: #00a2e6;
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 40px;

  :hover {
    color: #fff;
  }
}

/deep/ .el-upload-list__item {
  width: 136px;
  float: left;
  margin-right: 1rem;
  margin-top: 10px;
  height: 77px;
}

/deep/ .el-icon-plus {
  border: 1px solid #00a2e6;
  border-radius: 50%;
  color: #00a2e6;
  position: relative;
  top: 5px;
}

/deep/ .el-upload--picture-card i {
  font-size: 14px;
}

/deep/ .el-upload--picture-card {
  width: 136px;
  height: 77px;
  border: 1px #c0ccda;
  line-height: unset;
  margin-top: 10px;
}

/deep/ .el-card__body {
  padding: unset;
}

/** lipeng end*/

.banner {
  background-color: #f8f8f8;
}

.banner-width {
  width: 100%;

  .banner-img-font {
    background-image: url(~@/assets/banner/底图.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;

    p {
      position: relative;
      width: 800px;
      margin: 0 auto;
      top: 60px;
      color: #fff;

      strong {
        font-size: 24px;
        display: block;
        margin-bottom: 23px;
      }

      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  /* 技能表单 */

  .skill-form {
    width: 800px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: -250px;
    min-height: 350px;

    .skill-form-center {
      width: 720px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      top: 20px;
    }

    .skill-description {
      width: 720px;
      font-size: 12px;

      /deep/ .el-textarea__inner {
        border-radius: 0;
      }
    }

    .label-skill {
      font-size: 12px;
      color: #575757;
    }

    .skill-clas {
      display: flex;
      margin-bottom: 10px;

      .skill-classification {
        height: 36px;
        margin-right: 10px;
      }
    }

    .skill-Strong {
      display: block;
      line-height: 1;
      margin-top: 10px;
      color: #e4007f;
      font-size: 12px;
    }

    .skill-p {
      line-height: 1;
      margin-top: 10px;
      font-size: 14px;

      span {
        font-size: 12px;
        color: #575757;
        margin-right: 10px;
      }
    }

    .skill-select {
      width: 720px;
    }

    .skill-money {
      display: flex;
      width: 720px;

      .skill-money-left {
        button {
          width: 150px;
          height: 37px;
          cursor: pointer;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 14px;
            height: 14px;
          }

          span {
            font-size: 14px;
            color: #575757;
          }
        }

        button:first-of-type {
          margin-bottom: 10px;
        }
      }

      .skill-money-left1 {
        text-align: center;
        margin-bottom: 10px;
        background-color: #00a2e6;

        .skill-money-left12 {
          span {
            color: #fff;
            display: flex;
            align-items: center;
          }
        }
      }

      .skill-money-right {
        margin-left: 20px;
        width: 550px;

        .skill-money-right1 {
          width: 550px;
          height: 82px;
          border: 1px solid #b5b5b5;

          .skill-money-right-center {
            width: 530px;
            margin: 0 auto;
          }

          .skill-money-right-center-top {
            height: 34px;
            border-bottom: 1px solid #b5b5b5;

            p {
              font-size: 12px;
              color: #575757;
            }
          }

          .skill-money-right-center-bottom {
            display: flex;
            align-items: center;

            .min-price, .max-price {
              .el-input {
                width: 150px;
              }

              /deep/.el-form-item__label {
                font-size: 14px;
              }
            }
          }
        }

        .skill-money-right2 {
          width: 550px;
          height: 82px;
          border: 1px solid #b5b5b5;

          .skill-money-right-center {
            width: 530px;
            margin: 0 auto;
          }

          .skill-money-right-center-top {
            height: 34px;
            border-bottom: 1px solid #b5b5b5;

            p {
              font-size: 12px;
              color: #575757;
            }
          }

          .skill-money-right-center-bottom {
            display: flex;
            align-items: center;

            .min-price, .max-price {
              .el-input {
                width: 150px;
              }

              /deep/.el-form-item__label {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .skill-hint {
      font-size: 12px;

      span {
        display: block;
        color: #e4007f;
        margin-bottom: 10px;
      }

      ul {
        li {
          color: #474747;
          margin-bottom: 10px;
        }
      }
    }

    .skill-button {
      width: 440px;
      height: 136px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;

      button {
        width: 200px;
        height: 36px;
        background-color: #00a2e6;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
      }

      .skill-button-preview {
        background-color: #f4f4f4;
        color: #575757;
        margin-right: 40px;
        text-align: center;
        border-radius: 0;

        /deep/ span {
          position: relative;
          top: -4px;
        }
      }
    }
  }
}

.el-form-item {
  margin-bottom: 10px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk)
.el-form-item__label-wrap
> .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  color: green;
}

/deep/ .el-input__inner {
  border-radius: 0;
}

/deep/ .el-form-item__label {
  font-size: 18px;
}

/deep/ .el-textarea__inner {
  //el_input中的隐藏属性
  resize: none; //主要是这个样式
}

/deep/ .el-textarea textarea {
  padding-bottom: 30px;
}

/deep/ .el-textarea .el-input__count {
  height: 12px;
  line-height: 12px;
}

.dia-font {
  h2 {
    width: 720px;
    margin-top: 40px;
    font-size: 18px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 22px;

    ul {
      li {
        font-size: 12px;
        color: #474747;
        margin-bottom: 12px;
      }

      & > li:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .check-img-font {
    height: 171px;
    display: flex;
    margin-bottom: 10px;

    .check-img-border {
      width: 300px;
      height: 171px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .check-font-border {
      margin-left: 20px;
      font-size: 14px;

      ul {
        li {
          display: flex;
          line-height: 33px;
          width: 400px;
          border-bottom: 1px solid #b5b5b5;

          strong {
            margin-left: 20px;
            width: 58px;
            margin-right: 25px;
            color: #000;
          }

          span {
            color: #575757;
          }
        }
      }
    }
  }

  .check-font-border {
    margin-left: 20px;
    font-size: 14px;

    ul {
      li {
        display: flex;
        line-height: 33px;
        width: 400px;
        border-bottom: 1px solid #b5b5b5;

        strong {
          margin-left: 20px;
          width: 58px;
          margin-right: 25px;
          color: #000;
        }

        span {
          color: #575757;
        }
      }
    }
  }

  .check-img-box {
    width: 720px;
    margin-bottom: 40px;

    .check-img-boxs {
      width: 740px;
      display: flex;
      flex-wrap: wrap;

      .check-img-boxs-border {
        width: 136px;
        height: 77px;
        margin-right: 10px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      & > .check-img-boxs-border:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .dialog-footer {
    position: relative;
    top: -40px;
    display: flex;
    width: 440px;
    margin: 0 auto;
    line-height: 36px;

    button {
      width: 200px;
      height: 36px;
      font-size: 18px;
      border-radius: 0;
      margin-right: 30px;
    }

    /deep/ .el-button {
      span {
        position: relative;
        top: -4px;
      }
    }
  }
}

/deep/ .el-dialog__body {
  width: 720px;
  margin: 0 auto;
}

/deep/ .el-button {
  border-radius: 0;
}

/deep/ .skill-button-issue {
  span {
    position: relative;
    top: -3px;
  }
}

/deep/ .el-input__count {
  bottom: 10px;
}
</style>
